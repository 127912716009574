import React from 'react';
import Container from '@material-ui/core/Container';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

function About({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About ChordSL.com" />
      <Container maxWidth="md">
        <h4 id="ChordSLcom-is-here">ChordSL.com is here!</h4>

        <p>
          ChordSL.com is an online application to find your favourite Sri Lankan
          song lyrics. You can search from song names, artists names even from a
          fraction from a song.
        </p>

        <p>ChordSL.com is,</p>
        <ol>
          <li>Highly optimized for mobile performance and web performance.</li>
          <li>
            Heavily focusing on the purpose of the site (which is presenting
            lyrics)
          </li>
          <li>
            Will give you different experience based on the device you visit our
            site
          </li>
        </ol>

        <p>
          In addition to that,{' '}
          <strong>we are making our code artefacts open</strong>. If you are a
          developer you can help us to improve the site by contributing to these
          repos in Github.
        </p>

        <p>If you are willing to help us,</p>

        <p>
          <em>As a musician</em>, and want to update or add more songs to our
          collection, Contact us from the site or find details from our FB page
        </p>

        <p>
          <em>As a Developer</em>, you can find our Github organization as{' '}
          <a href="https://github.com/Open-SL">Open-SL</a>
        </p>

        <p>
          Finally, if you have any questions regarding the site please contact
          us from the contacts tab on the site. Until our next meet please share
          this with your friends and show us some love. ❤️
        </p>

        <p>
          <strong>ChordSL team</strong>
        </p>
      </Container>
    </Layout>
  );
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
